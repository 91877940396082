<template>
  <div>
    <div class="table-responsive">
      <div class="top-bar d-flex align-center mb-3">
        <a-select
          :default-value="!notFound ? 'inactive' : 'active'"
          class="col-3 mr-3"
          @change="handleChange"
        >
          <a-select-option value="active">
            Active
          </a-select-option>
          <a-select-option value="inactive">
            Inactive
          </a-select-option>
          <a-select-option value="revoked">
            Revoked
          </a-select-option>
          <a-select-option value="all">
            All
          </a-select-option>
        </a-select>
        <div class="created-on-label">
          <label class="form-label listing-date-label">Created On</label>
          <a-date-picker
            class="mr-3"
            @change="changeCreatedOn"
          />
        </div>
        <div class="expired-on-label">
          <label class="form-label listing-date-label">Expired On</label>
          <a-date-picker
            @change="changeExpiredOn"
          />
        </div>
      </div>
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>URL</th>
            <th>Created On</th>
            <th>Expired On</th>
            <th width="120px">
              Action
            </th>
          </tr>
        </thead>
        <tbody v-if="items && !!items.length">
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.url }}</td>
            <td>{{ moment(item.created_on).format('YYYY-MM-DD') }}</td>
            <td>{{ moment(item.expired_on).format('YYYY-MM-DD') }}</td>
            <td class="d-flex">
              <a
                class="a_action_icon bg-secondary d-flex justify-content-center p-0 mb-2 mr-2"
              >
                <i
                  class="fa fa-close"
                  aria-hidden="true"
                />
              </a>
              <router-link
                tag="a"
                class="a_action_icon bg-secondary d-flex justify-content-center p-0 mb-2 mr-2"
                :to="{name: 'view-certificate'}"
              >
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                />
              </router-link>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td
            colspan="6"
            class="text-center font-weight-bold py-4"
          >
            <h4 class="text-secondary">
              NO VALUES FOUND
            </h4>
          </td>
        </tbody>
      </table>
      <a-pagination
        v-if="total"
        class="pagination-right"
        :default-current="1"
        :total="total"
        @change="pagination($event)"
      />
    </div>
  </div>
</template>

<script>
    import axios from '@/axios'
    import moment from 'moment'
    import {Modal} from 'ant-design-vue'

    export default {
        data() {
            return {
                moment,
                step: 10,
                skip: 0,
                total: null,
                items: null,
                notFound: false,
                selectModel: 'active',
                createdOn: moment(new Date()).format('YYYY-MM-DD'),
                expiredOn: moment(new Date()).format('YYYY-MM-DD')
            }
        },
        mounted() {
            this.loadItems()
        },
        methods: {
            loadItems() {
                axios
                    .get(`certificate?status=${this.selectModel}&date%20start=${moment(this.createdOn).format('YYYY-MM-DD')}&date%20end=${moment(this.expiredOn).format('YYYY-MM-DD')}&take=10&skip=${this.skip}`)
                    .then((res) => {
                        this.items = res.data.data
                        if(Object.keys(this.items).length === 0){
                          this.notFound = false
                        }else {this.notFound = true}
                        this.total = res.data.count > this.step ? res.data.count : res.data.count
                    })
                    .catch(() => {})
            },
            changeCreatedOn(date, dateString) {
                this.createdOn = dateString
                this.loadItems()
            },
            changeExpiredOn(date, dateString) {
                this.expiredOn = dateString
                this.loadItems()
            },
            handleChange(val) {
                this.selectModel = val
                this.loadItems()
            },
            pagination(int) {
                this.skip = 0
                this.skip += this.step * int - this.step
                this.loadItems()
            },
            // approveConfirm(id) {
            //   let vm = this
            //   Modal.confirm({
            //     title: 'Do you want to approve this application?',
            //     onOk() {
            //       vm.approve(id)
            //     },
            //     class: 'test',
            //   })
            // },
            // rejectConfirm(id) {
            //   let vm = this
            //   Modal.confirm({
            //     title: 'Do you want to reject this application?',
            //     content: (
            //       <div class="d-flex flex-column" width="100%">
            //         <span>Enter the reason:</span>
            //         <input width="100%" id="input" />
            //       </div>
            //     ),
            //     onOk() {
            //       const reason = document.querySelector('#input').value
            //       vm.reject(id, reason)
            //     },
            //     class: 'test',
            //   })
            // },
            // approve(id) {
            //   axios
            //     .post(`registration/${id}/approve`)
            //     .then((res) => {
            //       console.log(res)
            //       this.success()
            //     })
            //     .catch((err) => {
            //       console.log(err)
            //       this.danger()
            //     })
            // },
            // reject(id, reason) {
            //   axios
            //     .post(`registration/${id}/reject`, {
            //       reason,
            //     })
            //     .then(() => {
            //       this.reason = null
            //       this.success()
            //     })
            //     .catch((err) => {
            //       this.reason = null
            //       console.log(err)
            //       this.danger()
            //     })
            // },
            // success() {
            //   const vm = this
            //   Modal.success({
            //     title: 'Operation completed successfully',
            //     onOk() {
            //       vm.loadItems()
            //     },
            //   })
            // },
            // danger() {
            //   Modal.error({
            //     title: 'Something went wrong, please update and try again',
            //   })
            // },
        },
    }
</script>
<style scoped>
    .listing-date-label {
        width: 100px;
        padding: 7px
    }

    .pagination-right {
        text-align: right;
        margin-right: 210px;
    }

    td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
    }
    .top-bar {
      flex-wrap: wrap;
    }
</style>

<style>

@media (max-width: 396px) {
  .ant-select-arrow {
    display: inline-block;
    font-style: normal;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 53%;
    right: -63px!important;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: #c8c4db;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
  }
 .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #e4e9f0;
    border-radius: 8px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 180% !important;
    height: 32px;
    padding: 0 11px;
  }
 .created-on-label, .expired-on-label {
   margin-top: 1rem;
 }
}

</style>
